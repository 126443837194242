import api from "./api";

export default {
  getHitMap(data) {
    return api.execute(`post`, `/hitmap/image`, data);
  },
  getHitMapVideo(data) {
    return api.execute(`post`, `/hitmap/video`, data);
  },
  getvideolist(data) {
    return api.execute(`post`, `/iwitness/videolist`, data);
  },
  getheatMapdataImage(data) {
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/heatmap/image`, data)
    }else{return api.execute(`post`, `/heatmap/image`, data);}
  },
  getheatMapdataVideo(data) {
    if(window.localStorage.getItem("user") == 'csp'){
      return api.execute(`post`,`/csp/heatmap/video`, data)
    }else{return api.execute(`post`, `/heatmap/video`, data);}
  },
};
