<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
      <h4 class="color-light ml-2 page_title"><span class="ul_text">HE</span>ATMAP</h4>
      </CRow>
      <CCardBody>
        <CRow class="col-md-12 col-12">
          <CCol class="col-md-4 col-12">
            <CRow>
              <label class="">Date Range</label>
            </CRow>
            <CRow class="">
              <span class="form-control" @click="()=>date_modal=true" style="cursor: pointer;">
                {{ changeDateFormate(startDate)}}   <b>-</b>  {{ changeDateFormate(endDate) }}
              </span>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
                
              >
              <date-range-picker 
                :from="date_data.endDate"
                :to="date_data.startdate"
                :future="false"
                :panel="date_data.panel"
                :showControls="false" 
                @select="update"/>
              <template #footer>
                <CButton @click="date_modal = false" color="danger">Close</CButton>
              </template>
              </CModal>
              <!-- <date-time-picker
                class="col-12"
                :endDate="endDate"
                :singleDate="false"
                :startDate="startDate"
                @onChange="dateFilter"
              /> -->
            </CRow>
          </CCol>
          <CCol class="col-md-3 col-12 ml-md-3">
            <CRow class="">
              <label class="">Campaign</label>
            </CRow>
            <CRow class="">
              <multiselect
                class=""
                v-model="mainObject.campid"
                placeholder="Campaign:"
                label="name"
                track-by="_id"
                :options="camp_list"
                :multiple="false"
                :taggable="false"
                :preselect-first="true"
                :close-on-select="true"
              >
              </multiselect>
            </CRow>
          </CCol>
          <CCol class="col-md-2 col-12 ml-md-3">
            <CRow class="">
              <label class="">Device Type</label>
            </CRow>
            <CRow>
              <multiselect
                class=""
                v-model="mainObject.devicetype"
                tag-placeholder="Add this as new tag"
                placeholder="Device Type:"
                :options="devicetypeopt"
                :multiple="false"
                :taggable="false"
                :preselect-first="true"
                :close-on-select="true"
              >
              </multiselect>
            </CRow>
          </CCol>
          <CCol class="ml-md-3">
            <CRow><label class="p-2"></label></CRow>
            <CRow class="mt-2">
              <CCol>
              <CButton
                class=""
                size=""
                type="submit"
                @click="onSubmit('Image')"
                variant=""
                color="success"
                >Image</CButton
              >
              </CCol>
              <CCol>
              <CButton
                class=""
                size=""
                type="submit"
                @click="onSubmit('Video')"
                variant=""
                color="info"
                >Video</CButton
              >
              </CCol>
              </CRow>
          </CCol>
         
        </CRow>
         <CRow class="col-md-12 pt-5">
            <h3 v-if="show_no_data_message">{{ no_data_message }}</h3>
         </CRow>
         <CRow class="col-md-12 pt-1">
          <CCol>
            <center>
              <video
              v-show="show_video"
              style="border: 2px solid black;"
              class="video_tag"
              ref="hmVideoRef"
              src=""
              id="video-container"
              width="50%"
              height="100%"
              controls
            ></video>
            </center>
          </CCol>
        </CRow>
        <div v-if="loading_data==true" class="col-md-12 pt-1">
          <center>
              <CElementCover
                :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
                :opacity="0.8"
              >
            <h1 class="d-inline">Loading...</h1>
              <CProgress class="mb-5">
              <CProgressBar
                color="success"
                variant="striped"
                animated
                :value="parseInt(uploadVStatus)"
              />
            </CProgress>
              </CElementCover>
            </center>
        </div>
      
        <CRow class="col-md-12" v-show="show_image">
          <CCol>
            <center>
              <img
                :src="image_url"
                class="img-fluid"
                style="border: 5px solid black;"
              />
            </center>
          </CCol>
        </CRow>
        
      </CCardBody>
    </CCard>
  </div>
</template>


<script>
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import CampaignControlService from "../../services/campaignControlService";
import ReportsController from "../../services/reportsController";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2/dist/sweetalert2.js";
let socket;
export default {
  name: "HitMap",
  components: {
    DateTimePicker,
    Multiselect,
  },
  data() {
    return {
      date_data:{startdate:null,enddate:null,panel:null},
      date_modal:false,
      show_no_video_message: false,
      show_video_button: false,
      uploadVStatus: 0,
      show_video: false,
      loading_data:false,
      image_data:false,
      startDate: new Date(),
      endDate: new Date(),
      mainObject: {
        edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        campid: "",
        devicetype: "",
      },
      camp_list: [],
      devicetypeopt: ["Desktop", "SmartPhone"],
      image_url: "",
      show_image: false,
      show_no_data_message: false,
      no_data_message: "No data available !!!",
    };
  },
  mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true" || window.localStorage.getItem("iwitness") == 'false'
    ) {
      this.$router.push("/");
    } else {
      if (window.localStorage.getItem("user") == "null") {
        alert("Please Enter User Name and Password");
        this.$router.push("Login");
      } else {
        this.mainObject.campid = this.$root.$data.heatmapdata;
        this.dateFilter({startDate:new Date(this.startDate.setHours(0,0,0,0)),endDate:new Date(this.endDate.setHours(23,59,0,0))});
        this.getcampList();
      }
    }
  },
  methods: {
    changeDateFormate(d){
      var p = new Date(d);
      return p.toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long"})
    },
    update(values) {
      this.date_data.startdate = values.from
      this.date_data.enddate = values.to
      this.date_data.panel = values.panel
      this.dateFilter({startDate:new Date(values.from),endDate:new Date(values.to)})
      this.date_modal = false;
    },
    async onSubmit(txt) {
      if (this.validate()) {
        this.loading_data=true;
        this.show_image = false;
        this.show_video = false;
        this.uploadVStatus = 0
        var postdata = {
          sdate: this.mainObject.sdate,
          edate: this.mainObject.edate,
          campid: this.mainObject.campid._id,
          devicetype: this.mainObject.devicetype,
        };
        try {
          this.show_no_data_message = false
          var response
          // Image API call
          if(txt == "Image"){
             response = await ReportsController.getHitMap(postdata);
            this.loading_data = false
            if (response.result) {
            this.image_url = response.imageUrl;
            this.show_image = true;
            this.show_video = false;
          } else {
            this.show_no_data_message = true
          }
          }
          //  Video API Call
          if(txt == "Video"){
            response = await ReportsController.getHitMapVideo(postdata);
            if (response.result) { 
           
            try {
              if (socket.readyState == WebSocket.OPEN) {
                socket.close();
              }
            } catch (e) {
              console.log("ERROR: error in check status" + e);
            }

            socket = new WebSocket(
              process.env.VUE_APP_SOCKETURL + "/" + response.videoUrl
            );
            let p;
            let videoId = response.videoUrl;

            socket.onopen = function (e) {
              console.log("[open] Connection established");
              console.info("Sending to server");
              let data = {
                event: "hmstatus",
                data: videoId,
              };
              socket.send(JSON.stringify(data));
            };
            socket.onerror = function (error) {
              console.error("error:", error);
              throw new Error("Unable to Open Socket");
            };
            socket.onclose = function (e) {
              console.log("[close] connection closing");
            };
            socket.onmessage = function (event) {
              //  console.log(event)
              //  console.log("received data"+event.data)
              try {
                let socketResponse = JSON.parse(event.data);
                this.uploadVStatus = socketResponse.status;
                if (this.uploadVStatus == "100") {
                  this.show_video_button = false;
                  this.loading = false;
                  if (socketResponse.videoUrl) {
                    socket.close(1000, "Work complete");
                    this.loading_data = false
                    this.show_video = true;
                    // this.$refs.hmVideoRef.src = ""
                    this.$refs.hmVideoRef.src = socketResponse.videoUrl;
                    this.$refs.hmVideoRef.play();
                   
                  } else {
                    socket.close(1000, "Work complete");
                    Swal.fire({
                      title: "Error!",
                      text: "Video Not Available !!!",
                      icon: "error",
                      confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                    });
                    this.show_no_video_message = true;
                    this.show_video_button = false;
                    this.show_video = false;
                  
                  }
                  
                }
              } catch (e) {
                console.log(e);
              }
            }.bind(this);
          } else {
                this.loading_data = false
                this.show_no_data_message = true
            }
          }
        } catch (e) {
          // alert("connection Error!");
        }
        
      } else {
        alert("Please Select Campaign");
      }
    },
    validate() {
      if (this.mainObject.campid == undefined || this.mainObject.campid == "") {
        return false;
      }
      return true;
    },
    async getcampList() {
      var response = await CampaignControlService.get();
      var campaign = response.message.campaigns;
      campaign.map((t) => {
        this.camp_list.push({
          _id: t._id,
          name: t.campname,
        });
      });
    },
    dateFilter(data) {
      this.mainObject.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.mainObject.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate;
      this.endDate = data.endDate;
    },
  },
};
</script>
